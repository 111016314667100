<template>
  <v-dialog
    v-model="isVisible"
    max-width="400"
    :persistent="savingIsLoading"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        fab
        small
        :disabled="instancesAreLoading"
        color="primary"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="primary white--text">{{ actionName }} une instance</v-card-title>

      <v-card-text>
        <v-radio-group
          label="Type d'instance :"
          v-model="type"
          mandatory
          :disabled="editedIndex !== -1"
          @change="clearDates"
        >
          <v-radio
            label="DAHU"
            value="DAHU"
          ></v-radio>

          <v-radio
            label="ENF"
            value="ENF"
          ></v-radio>

          <v-radio
            label="ENF Vétérans"
            value="ENFV"
          ></v-radio>

          <v-radio
            label="ENR"
            value="ENR"
          ></v-radio>

          <v-radio
            label="PSS"
            value="PARA"
          ></v-radio>
        </v-radio-group>

        <v-menu
          v-model="startDateMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              label="Date de début"
              :value="start_date | formatDate"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              :disabled="editedIndex !== -1 && editedItem.status === 'En cours'"
            ></v-text-field>
          </template>

          <v-date-picker
            v-model="start_date"
            @input="startDateMenu = false"
            no-title
            width="352"
            first-day-of-week="1"
            :min="today"
            :max="oneYearFromToday"
            :allowed-dates="notInInstancesDates"
            :events="checkEvent"
          ></v-date-picker>
        </v-menu>

         <v-menu
          v-model="startHourMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              label="Heure de début"
              :value="startHour"
              prepend-icon="mdi-clock-time-eight-outline"
              readonly
              v-bind="attrs"
              v-on="on"
              :disabled="editedIndex !== -1 && editedItem.status === 'En cours'"
            ></v-text-field>
          </template>

          <v-time-picker
            v-model="startHour"
            @click:minute="startHourMenu = false"
            full-width
            format="24hr"
            :allowed-minutes="checkIfMinuteIsAllowed"
          ></v-time-picker>
        </v-menu>

        <v-menu
          v-model="endDateMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              label="Date de fin"
              :value="end_date | formatDate"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>

          <v-date-picker
            v-model="end_date"
            @input="endDateMenu = false"
            no-title
            width="352"
            first-day-of-week="1"
            :min="start_date"
            :max="oneYearFromToday"
            :allowed-dates="beforeStartNextInstance"
            :events="checkEvent"
          ></v-date-picker>
        </v-menu>

        <v-menu
          v-model="endHourMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              label="Heure de fin"
              :value="endHour"
              prepend-icon="mdi-clock-time-four-outline"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>

          <v-time-picker
            v-model="endHour"
            @click:minute="endHourMenu = false"
            full-width
            format="24hr"
            :min="minEndHour"
            :allowed-minutes="checkIfMinuteIsAllowed"
          ></v-time-picker>
        </v-menu>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn
          @click="isVisible = false"
          text
          :disabled="savingIsLoading"
        >
          Annuler
        </v-btn>

        <v-spacer></v-spacer>

        <v-btn
          @click="saveInstance"
          text
          color="primary"
          :loading="savingIsLoading"
        >
          {{ actionName }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'SaveInstanceDialog',

  props: {
    value: Boolean,
    editedIndex: Number,
    editedItem: Object,
  },

  data: () => ({
    type: 'DAHU',
    start_date: '',
    end_date: '',
    startDateMenu: false,
    endDateMenu: false,
    startHour: null,
    endHour: null,
    startHourMenu: false,
    endHourMenu: false,
    today: new Date().toISOString().substr(0, 10),
    oneYearFromToday: new Date(new Date().setFullYear(new Date().getFullYear() + 1))
      .toISOString().substr(0, 10),
    savingIsLoading: false,
  }),

  computed: {
    ...mapState('instances', ['instances', 'instancesAreLoading']),

    isVisible: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },

    actionName() {
      return this.editedIndex === -1 ? 'Créer' : 'Modifier';
    },

    filteredInstances() {
      if (!this.editedItem) return this.selectedTypeInstances;
      return this.selectedTypeInstances.filter((instance) => instance.id !== this.editedItem.id);
    },

    selectedTypeInstances() {
      return this.instances.filter((instance) => instance.type === this.type);
    },

    nextInstanceStartDate() {
      let nextStartDate = null;

      this.filteredInstances.forEach((instance) => {
        if (instance.start_date > this.start_date
        && (!nextStartDate || instance.start_date < nextStartDate)) {
          nextStartDate = instance.start_date;
        }
      });
      return nextStartDate;
    },

    minEndHour() {
      if (this.start_date === this.end_date) return this.startHour;
      return undefined;
    },
  },

  methods: {
    ...mapMutations({ showNotification: 'SET_NOTIFICATION' }),

    async saveInstance() {
      await this.$store.dispatch('getAccessToken');
      let notificationText;
      let notificationColor;
      try {
        this.savingIsLoading = true;
        const response = await this.$http.post('admin/instance-save.php', {
          start_date: this.start_date,
          start_hour: this.startHour,
          end_date: this.end_date,
          end_hour: this.endHour,
          type: this.type,
          id: this.editedIndex !== -1 ? this.editedItem.id : null,
        });
        this.$store.commit('instances/UPSERT_INSTANCE', {
          index: this.editedIndex,
          instance: response.data.instance,
        });
        notificationText = response.data.message;
        notificationColor = 'success';
        this.isVisible = false;
      } catch (error) {
        notificationText = error.response.data;
        notificationColor = 'error';
      } finally {
        this.savingIsLoading = false;
        this.showNotification({
          isVisible: true,
          text: notificationText,
          color: notificationColor,
        });
      }
    },

    notInInstancesDates(date) {
      return !this.filteredInstances.some(
        (instance) => date >= instance.start_date && date <= instance.end_date,
      );
    },

    beforeStartNextInstance(date) {
      if (!this.nextInstanceStartDate || date < this.nextInstanceStartDate) return true;
      return false;
    },

    checkEvent(date) {
      const instances = this.selectedTypeInstances;
      const instancesLength = this.selectedTypeInstances.length;
      for (let i = 0; i < instancesLength; i += 1) {
        if (date >= instances[i].start_date && date <= instances[i].end_date) {
          switch (instances[i].status) {
            case 'En cours': return 'success';
            case 'En attente': return 'warning';
            case 'Terminée': return 'primary';
            // no default
          }
        }
      }
      return false;
    },

    clearDates() {
      this.start_date = '';
      this.end_date = '';
    },

    checkIfMinuteIsAllowed(n) {
      return n % 5 === 0;
    },
  },

  watch: {
    value(val) {
      if (!val) {
        this.start_date = '';
        this.end_date = '';
        this.type = 'DAHU';
        this.startHour = null;
        this.endHour = null;
      } else if (this.editedIndex !== -1) {
        this.type = this.editedItem.type;
        this.start_date = this.editedItem.start_date;
        this.end_date = this.editedItem.end_date;
        this.startHour = this.editedItem.start_hour;
        this.endHour = this.editedItem.end_hour;
      }
    },
  },
};
</script>
