var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"my-12"},[_c('v-card',{attrs:{"outlined":""}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Instances")]),_c('v-spacer'),(_vm.$store.getters.hasAdminRole)?_c('save-instance-dialog',{attrs:{"editedIndex":_vm.editedIndex,"editedItem":_vm.editedItem},model:{value:(_vm.saveInstanceDialogIsVisible),callback:function ($$v) {_vm.saveInstanceDialogIsVisible=$$v},expression:"saveInstanceDialogIsVisible"}}):_vm._e(),(_vm.$store.getters.hasAdminRole)?_c('delete-instance-dialog',{attrs:{"editedIndex":_vm.editedIndex,"editedItem":_vm.editedItem},model:{value:(_vm.deleteInstanceDialogIsVisible),callback:function ($$v) {_vm.deleteInstanceDialogIsVisible=$$v},expression:"deleteInstanceDialogIsVisible"}}):_vm._e()],1),_c('v-data-table',{attrs:{"headers":_vm.compHeaders,"items":_vm.instances,"loading":_vm.instancesAreLoading},on:{"click:row":_vm.goToCursusView},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(item.type === 'PARA' ? 'PSS' : item.type))])]}},{key:"item.start_date",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"py-2"},[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.start_date))+" "),_c('div',{staticClass:"text-caption text--secondary"},[_vm._v(" à "+_vm._s(item.start_hour)+" ")])])]}},{key:"item.end_date",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"py-2"},[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.end_date))+" "),_c('div',{staticClass:"text-caption text--secondary"},[_vm._v(" à "+_vm._s(item.end_hour)+" ")])])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":_vm.getStatusColor(item),"x-small":""}},[_vm._v(" mdi-circle ")]),_c('span',{class:("font-weight-medium " + (_vm.getStatusColor(item)) + "--text")},[_vm._v(" "+_vm._s(item.status)+" ")])],1)]}},(_vm.$store.getters.hasAdminRole)?{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":item.status === 'Terminée'}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"color":"secondary"},on:{"click":function($event){$event.stopPropagation();return _vm.editInstance(item)}}},[_c('v-list-item-title',[_vm._v("Modifier")])],1),(item.status === 'En attente')?_c('v-list-item',{on:{"click":function($event){$event.stopPropagation();return _vm.deleteInstance(item)}}},[_c('v-list-item-title',{staticClass:"error--text"},[_vm._v("Supprimer")])],1):_vm._e()],1)],1)]}}:null],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }