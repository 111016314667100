<template>
  <v-dialog
    v-model="isVisible"
    max-width="350"
    :persistent="deletionIsLoading"
    v-if="editedItem"
  >
    <v-card>
      <v-card-title class="error white--text">Supprimer une instance</v-card-title>

      <v-card-text class="pt-5">
        <div>
          Êtes-vous sûr de vouloir supprimer l'instance
          <span class="font-weight-bold">
            {{ editedItem.type }}
            du {{ editedItem.start_date | formatDate }}
            au {{ editedItem.end_date | formatDate }}
          </span>
          ?
        </div>

        <div class="mt-2">Toutes les données qui lui sont attachées seront perdues.</div>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn
          @click="isVisible = false"
          text
          :disabled="deletionIsLoading"
        >
          Annuler
        </v-btn>

        <v-spacer></v-spacer>

        <v-btn
          @click="deleteInstance"
          text
          color="error"
          :loading="deletionIsLoading"
        >
          Supprimer
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  name: 'DeleteInstanceDialog',

  data: () => ({
    deletionIsLoading: false,
  }),

  props: {
    value: Boolean,
    editedIndex: Number,
    editedItem: Object,
  },

  computed: {
    isVisible: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },

  methods: {
    ...mapMutations({ showNotification: 'SET_NOTIFICATION' }),
    ...mapMutations('instances', ['DELETE_INSTANCE']),

    async deleteInstance() {
      await this.$store.dispatch('getAccessToken');
      let notificationText;
      let notificationColor;
      try {
        this.deletionIsLoading = true;
        const response = await this.$http.post('admin/instance-delete.php', this.editedItem);
        this.DELETE_INSTANCE(this.editedIndex);
        notificationText = response.data;
        notificationColor = 'success';
      } catch (error) {
        notificationText = error.response.data;
        notificationColor = 'error';
      } finally {
        this.deletionIsLoading = false;
        this.showNotification({
          isVisible: true,
          text: notificationText,
          color: notificationColor,
        });
        this.isVisible = false;
      }
    },
  },
};
</script>
