<template>
  <v-container class="my-12">
    <v-card outlined>
      <v-toolbar flat>
        <v-toolbar-title>Instances</v-toolbar-title>

        <v-spacer></v-spacer>

        <save-instance-dialog
          v-if="$store.getters.hasAdminRole"
          v-model="saveInstanceDialogIsVisible"
          :editedIndex="editedIndex"
          :editedItem="editedItem"
        ></save-instance-dialog>

        <delete-instance-dialog
          v-if="$store.getters.hasAdminRole"
          v-model="deleteInstanceDialogIsVisible"
          :editedIndex="editedIndex"
          :editedItem="editedItem"
        ></delete-instance-dialog>
      </v-toolbar>

      <v-data-table
        :headers="compHeaders"
        :items="instances"
        :loading="instancesAreLoading"
        @click:row="goToCursusView"
      >
        <template v-slot:item.type="{ item }">
          <span class="font-weight-medium">{{ item.type === 'PARA' ? 'PSS' : item.type }}</span>
        </template>

        <template v-slot:item.start_date="{ item }">
          <div class="py-2">
            {{ item.start_date | formatDate }}

            <div class="text-caption text--secondary">
              à {{ item.start_hour }}
            </div>
          </div>
        </template>

        <template v-slot:item.end_date="{ item }">
          <div class="py-2">
            {{ item.end_date | formatDate }}

            <div class="text-caption text--secondary">
              à {{ item.end_hour }}
            </div>
          </div>
        </template>

        <template v-slot:item.status="{ item }">
          <div class="d-flex align-center">
            <v-icon
              :color="getStatusColor(item)"
              x-small
              class="mr-2"
            >
              mdi-circle
            </v-icon>

            <span
              :class="`font-weight-medium ${getStatusColor(item)}--text`"
            >
              {{ item.status }}
            </span>
          </div>
        </template>

        <template v-slot:item.actions="{ item }"
          v-if="$store.getters.hasAdminRole"
        >
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                :disabled="item.status === 'Terminée'"
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                @click.stop="editInstance(item)"
                color="secondary"
              >
                <v-list-item-title>Modifier</v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="item.status === 'En attente'"
                @click.stop="deleteInstance(item)"
              >
                <v-list-item-title class="error--text">Supprimer</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import SaveInstanceDialog from '@/components/instance/SaveInstanceDialog.vue';
import DeleteInstanceDialog from '@/components/instance/DeleteInstanceDialog.vue';
import { mapState, mapActions } from 'vuex';
import { getStatusColor } from '@/mixins/methodsMixin';

export default {
  name: 'AdminInstances',

  components: {
    'delete-instance-dialog': DeleteInstanceDialog,
    'save-instance-dialog': SaveInstanceDialog,
  },

  mixins: [getStatusColor],

  data: () => ({
    headers: [
      { text: 'TYPE', value: 'type' },
      { text: 'DEBUT', value: 'start_date' },
      { text: 'FIN', value: 'end_date' },
      { text: 'STATUT', value: 'status' },
    ],
    saveInstanceDialogIsVisible: false,
    deleteInstanceDialogIsVisible: false,
    editedIndex: -1,
    editedItem: null,
  }),

  created() {
    this.getInstances();
  },

  computed: {
    ...mapState('instances', ['instances', 'instancesAreLoading']),

    compHeaders() {
      if (this.$store.getters.hasAdminRole) {
        return [...this.headers, {
          text: '',
          value: 'actions',
          sortable: false,
          align: 'right',
        }];
      }
      return this.headers;
    },
  },

  methods: {
    ...mapActions('instances', ['getInstances']),

    editInstance(item) {
      this.editedIndex = this.instances.indexOf(item);
      this.editedItem = { ...item };
      this.saveInstanceDialogIsVisible = true;
    },

    deleteInstance(item) {
      this.editedIndex = this.instances.indexOf(item);
      this.editedItem = { ...item };
      this.deleteInstanceDialogIsVisible = true;
    },

    goToCursusView(instance) {
      if (instance.type === 'DAHU') this.$router.push(`/admin/dahu/${instance.id}`);
      else if (instance.type === 'ENF') this.$router.push(`/admin/enf/${instance.id}`);
      else if (instance.type === 'ENFV') this.$router.push(`/admin/enfv/${instance.id}`);
      else if (instance.type === 'PARA') this.$router.push(`/admin/pss/${instance.id}`);
      else this.$router.push(`/admin/enr/${instance.id}`);
    },

    close() {
      this.editedIndex = -1;
      this.editedItem = null;
    },
  },

  watch: {
    saveInstanceDialogIsVisible(val) {
      if (!val) {
        this.close();
      }
    },

    deleteInstanceDialogIsVisible(val) {
      if (!val) {
        this.close();
      }
    },
  },
};
</script>
